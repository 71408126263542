import {createApp} from 'petite-vue';
import lazy from "./directives/lazy";


// window.touchHandler = ('ontouchstart' in document.documentElement ? "touchstart" : "click");
// window.global = window.global || {};
// window.service = 'http://api.ebee.lo';

window.app = createApp({
    service: {
        ready: false,
    },
    account: {
        id: false,
    },
    search: {
    },
    mounted() {
        console.log("mounted");
    }
});

app.directive('lazy', lazy);
app.mount();
