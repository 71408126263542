/**
 * @ngdoc directive
 * @name vue.directive:vLazy
 * @see - https://stackoverflow.com/questions/5573096/detecting-webp-support
 * @example <svg data-src="{url}" data-web></svg>
 */

export default () => {

    let items = document.querySelectorAll("[data-src]");

    let callback = (modern) => {

        if ("IntersectionObserver" in window ) {

            let observer = new IntersectionObserver((entries) => {
                console.log('intersect x');
                console.log(modern);
                entries.forEach((entry) => {
                    if ( entry.intersectionRatio > 0) {

                        let tag = entry.target;
                        let web = modern && tag.dataset.hasOwnProperty('web');
                        let src = web ? tag.dataset.src.replace(/(jpg|jpeg|png)$/, 'webp') : tag.dataset.src;
                        // Background
                        if ( tag.tagName.toLowerCase() === 'svg') {
                            tag.style.backgroundImage = "url('" + src + "')";
                            tag.style.backgroundSize = "cover";
                        }
                        // Attribute
                        if ( tag.tagName.toLowerCase() === 'img') {
                            tag.src = src;
                        }

                        delete tag.dataset.src;

                        observer.unobserve(entry.target);
                    }
                });
            });

            Array.prototype.slice.call(items).forEach((i) => {
                observer.observe(i);
            });

            return true;
        }
    };

    let options = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        // lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        // alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        // animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
    };

    // Test webP
    let picture = new Image();
    picture.onload = () => {
        callback(picture.width > 0 && picture.height > 0);
    };
    picture.onerror = () => {
        callback(false);
    };
    picture.src = "data:image/webp;base64," + options.lossy;

};